import "@fortawesome/fontawesome-free/js/all";
import "include-media"


document.addEventListener("DOMContentLoaded", function () {
    document.getElementById("lateral_bar_button").addEventListener("click", w3_open);
    document.getElementById("close_lateral_button").addEventListener("click", w3_close);

    $(".app_form").click(function (e) {
        var x = document.getElementById("cuestionario");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    });

    $("#join_button").click(function (e) {
        e.preventDefault();

        if (!$('#join_name').val() || !$('#join_surname').val() || !$('#join_email').val()) {
            $('.field_error').css("display","block");
            $('.privacy_error').css("display","none");
        } else if (!$('#privacy_policy_1').prop('checked')) {
            $('.field_error').css("display","none");
            $('.privacy_error').css("display","block");
        } else {
            $('.field_error').css("display","none");
            $('.privacy_error').css("display","none");

            var name = $('#join_name').val();
            var surname = $('#join_surname').val();
            var email = $('#join_email').val();
            var category = "";


            var commsMaldita = false;
            var commsFad = false;

            if($('#privacy_policy_2').prop('checked'))
                commsFad = true;

            if($('#privacy_policy_3').prop('checked'))
                commsMaldita = true;

            console.log($('#category').prop('selectedIndex'));
            switch ($('#category').prop('selectedIndex')) {
                case 0:
                    category = "Docente"
                    break;
                case 1:
                    category = "Medios"
                    break;
                case 2:
                    category = "Asociacion"
                    break;
                case 3:
                    category = "Joven"
                    break;
                case 4:
                    category = "Otro"
                    break;
                default:
                    category = "Otro"
            }

            $.post("https://nomorehaters.es/registercontroller.php",
                {
                    name: name,
                    surname: surname,
                    email: email,
                    category: category,
                    commsFad: commsFad,
                    commsMaldita: commsMaldita
        },
                function (data, status) {
                    $(".success").css("display","block");
                });
        }

    });


});


function w3_open() {
    document.getElementById("lateral_nav").style.display = "block";
}

function w3_close() {
    document.getElementById("lateral_nav").style.display = "none";
}


